import React, { useEffect, useMemo, useState } from 'react';
import './SignUp.css';
import { Box, Checkbox, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Link, useAsyncValue, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUserData, fetchCountry, hideStatus, postUserData } from '../../../Store/Slices/Cart_Item';
// import { Visibility, VisibilityOff } from '@mui/icons-material';

function SignUp() {
  const dispatch = useDispatch();
  const country = useSelector((state) => state.cartItem?.country);
  const status = useSelector((state) => state.cartItem?.signUp);
  const navigate = useNavigate();
  const [formData, setFormData] = useState(
    {
      first_name: '',
      last_name: '',
      company_name: '',
      country: '',
      city: '',
      street_address: '',
      postal_code: '',
      email_address: '',
      phone_number: '',
      password: ''
    }
  )
    useEffect(() => 
    {
      dispatch(fetchCountry());
    }, [dispatch])

    useEffect(() =>
    {
      if(status === true)
      {
        navigate('/Login');
        dispatch(hideStatus())
      }
    },[dispatch, navigate, status])

  const [validationErrors, setValidationErrors] = useState({
    email_address: '',
    password: '',
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addUserData(formData));
    dispatch(postUserData(formData));
 
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    switch (name) {
      case 'email_address':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          email_address: validateEmail(value),
        }));
        break;
      case 'password':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          password: validatePassword(value),
        }));
        break;
      default:
        break;
    }
    const validateEmail = (email) => {
      if (!/\S+@\S+\.\S+/.test(email)) {
        return 'Please enter a valid email address';
      }
      return '';
    };

    const validatePassword = (password) => {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;
      if (password.length < 8) {
        return 'Password must be at least 8 characters long';
      }
      if (!passwordRegex.test(password)) {
        return 'Password must contain at least one uppercase letter, one digit, and one special character';
      }
      return '';
    };
  }

  return (
    <div className="signup_wrapper">
      <div className="inner-sec-container">
        <div className="signup_parent_section">
          <div className="signup_content_section mt-5">
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <TextField
                    required
                    label="First Name"
                    fullWidth
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <TextField
                    required
                    label="Last Name"
                    fullWidth
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-lg-6 mt-4">
                  <TextField
                    label="Company Name(optional)"
                    fullWidth
                    type="text"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Country/Region</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        required
                        value={formData.country}
                        label="Country/Region"
                        name="country"
                        onChange={handleChange}
                      >
                      {
                        country?.countries?.map((country , index) => 
                        {
                          return <MenuItem value={country.name} key={index}>{country.name}</MenuItem>
                        })
                      }
                        
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="col-lg-6 mt-4">
                  <TextField
                    required
                    label="Street Address"
                    fullWidth
                    placeholder="House number and street name"
                    type="text"
                    name="street_address"
                    value={formData.street_address}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <TextField
                    required
                    label="Town / City"
                    fullWidth
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <TextField
                    required
                    label="Postcode"
                    fullWidth
                    type="number"
                    name="postal_code"
                    value={formData.postal_code}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <TextField
                    required
                    label="Email Address"
                    fullWidth
                    type="email"
                    name="email_address"
                    value={formData.email_address}
                    onChange={handleChange}
                    error={!!validationErrors.email_address}
                      helperText={validationErrors.email_address}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <TextField
                    required
                    label="Phone"
                    fullWidth
                    type="number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <FormControl sx={{ width: '100%' }} variant="outlined">

                    <TextField
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      fullWidth
                      required
                      label="Password"
                      placeholder="Please enter your password"
                      value={formData.password}
                      name='password'
                      onChange={handleChange}
                      error={!!validationErrors.password}
                      helperText={validationErrors.password}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>
              </div>
              <Checkbox className='chkbox' />
              <label className='createAccLabel'>I accept the Terms of Use & Privacy Policy</label>
              <button className='signup_btn' type='submit'>Sign Up</button>
              <label className='createAccLabel'>Already have an account?</label>
              <Link to="/Login" className='login_here'>Login here</Link>
            </form>












          </div>

        </div>
      </div>
    </div>
  )
}

export default SignUp