import './Login.css';
import { Checkbox, FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postSignIn, teriYadein } from '../../Store/Slices/Cart_Item';
import UserDashboard from '../../UserDashboard/UserDashboard';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';


function Login() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.cartItem?.login);
  const navigate = useNavigate();
  const [formData, setFormData] = useState(
    {
      email_address: '',
      password: ''
    }
  )
  const [validationErrors, setValidationErrors] = useState({
    email_address: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postSignIn(formData));
    navigate('/')
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
    switch (name) {
      case 'email_address':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          email_address: validateEmail(value),
        }));
        break;
      case 'password':
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          password: validatePassword(value),
        }));
        break;
      default:
        break;
    }
    const validateEmail = (email) => {
      if (!/\S+@\S+\.\S+/.test(email)) {
        return 'Please enter a valid email address';
      }
      return '';
    };

    const validatePassword = (password) => {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/;
      if (password.length < 8) {
        return 'Password must be at least 8 characters long';
      }
      if (!passwordRegex.test(password)) {
        return 'Password must contain at least one uppercase letter, one digit, and one special character';
      }
      return '';
    };
  };

  const RememberMe = (event) =>
  {
    dispatch(teriYadein(event.target.checked));
  }

  return (


    <>

{
  login === true ? <UserDashboard/> 
    : <div className="login_wrapper">
      <div className="inner-sec-container">
        <div className="login_parent_section">
          <div className="login_content_section mt-5">
            <div className="login_content_area">
              <h2>Login</h2>
              <form onSubmit={handleSubmit}>
                <TextField
                  required
                  label="Email"
                  fullWidth
                  type="email"
                  placeholder="Please enter your email address"
                  margin="normal"
                  value={formData.email_address}
                  name='email_address'
                  onChange={handleChange}
                  error={!!validationErrors.email_address}
                  helperText={validationErrors.email_address }
                />
                <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
 
                  <TextField
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required
                    label="Password"
                    placeholder="Please enter your password"
                    margin="normal"
                    value={formData.password}
                    name='password'
                    error={!!validationErrors.password}
                      helperText={validationErrors.password}
                    onChange={handleChange}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>


                <button className="sign_in_btn" type='submit'>Sign In</button>
                <Checkbox className='chkbox' onChange={RememberMe} />
                <label className='remeber_me_Label'>Remember Me</label>
              </form>
              <Link to="/Reset-Password">Lost your password?</Link>
              <hr />
              <Link to="/SignUp" className='createAcc-btn'>Create an Account</Link>
              <Link to="/" className='home-btn mt-2'>Back to Home</Link>
            </div>
          </div>
        </div>
      </div>
      
    </div>
}
   
   
    </>
  )
}

export default Login