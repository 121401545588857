import React from 'react';
import './OrderTracking.css'
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import img from '../../Images/order_tracking_img1.png';
import img2 from '../../Images/order_tracking_img2.png';
import img3 from '../../Images/order_tracking_img3.png';
import img4 from '../../Images/order_tracking_img4.png';

function OrderTracking() {
    return (
        <div className="order_tracking_wrapper">
            <div className="inner-sec-container">
                <div className="order_tracking_parent_section">
                    <Breadcrum item={""} />
                    <div className="order_tracking_content_section mt-4">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="order_tracking_desc_section">
                                    <h2>Shipping</h2>
                                    <p>Your Orders are Dispatched Within <b>72</b> Hours of Cleared Payments</p>
                                    <p>All Small Parcels are sent Via Royal Mail 2nd Class Signed For Service (Free)</p>
                                    <p>Larger Items are dispatched Via Fully Tracked Courier</p>
                                    <p>We Also offer Different Delivery UK Services click the Link <b>UK Shipping</b> and International Shipping on Request</p>
                                    <p>Once Dispatched you will recieve confirmation of the Courier and Tracking number via email.</p>
                                    <p><b>To Track your Order please us the Link provided Below</b></p>
                                    <p><b>Track your order</b></p>
                                    <p>List of courier companies:</p>
                                    <ul>
                                        <li>
                                            <a href="https://track.dhlparcel.co.uk/" target="blank">DHL Parcels UK </a>
                                        </li>
                                        <li>
                                            <a href='https://www.collectplus.co.uk' target="blank">Collect plus</a>
                                        </li>
                                        <li>
                                            <a href="https://www.parcelforce.com/track-trace" target="blank">Parcelforce</a>
                                        </li>
                                        <li>
                                            <a href="https://www.evri.com/track/" target="blank">Hermes</a>
                                        </li>
                                        <li>
                                            <a href="https://www.dpd.co.uk/content/how-can-we-help/index.jsp" target="blank">DPD</a>
                                        </li>
                                        <li>
                                            <a href="https://www.royalmail.com/track-your-item" target="blank">Royal Mail</a>
                                        </li>
                                        <li>
                                            <a href="https://www.yodel.co.uk/track/" target="blank">Yodel</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="order_tracking_img_section">
                                    <div className="row">
                                        <div className="col-lg-6 mb-4">
                                        <div className="order_tracking_img_area">
                                            <img src={img} alt="" />
                                        </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                        <div className="order_tracking_img_area">
                                            <img src={img2} alt="" />
                                        </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                        <div className="order_tracking_img_area">
                                            <img src={img3} alt="" />
                                        </div>
                                        </div>
                                        <div className="col-lg-6 mb-4">
                                        <div className="order_tracking_img_area">
                                            <img src={img4} alt="" />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderTracking