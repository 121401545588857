import React, { useEffect, useMemo, useState } from 'react'
import './Checkout.css'
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import return_customer from '../../Images/returning-visitor.png';
import payment_img from '../../Images/payment_img.png';
import coupon_icon from '../../Images/coupon.png';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountry, fetchCoupon, fetchOrderID, postCodOrder, postUserOrder, userDobaraSetKr } from '../../Store/Slices/Cart_Item';
import { StorageItem, getItem, getSessionItem } from '../../Utilis/local-storage.utils';

function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let user = useSelector((state) => state.cartItem?.userData?.user?.user);
  const orderID = useSelector((state) => state.cartItem?.orderID);
  const thanku = useSelector((state) => state.cartItem?.thanku);
  const cartitem = useSelector((state) => state.cartItem.cartItems?.cart?.items);
  const cartID = useSelector((state) => state.cartItem.cartItems?.cart?._id);
  const Subtotal = useSelector((state) => state.cartItem.cartItems?.cart?.subtotal);
  const country = useSelector((state) => state.cartItem?.country);
  const [coupon, setCoupon] = useState(false);
  const [diffAddress, setDiffAddress] = useState(false);
  const [formData, setFormData] = useState(user || "");
  useEffect(() => {
    if (user) {
      setFormData(user)
      dispatch(fetchCountry());
    }
    else {
      let userNaya = {
        message: "login successfully",
        token: getItem(StorageItem.JwtToken) || getSessionItem(StorageItem.JwtToken),
        user: getItem(StorageItem.LoggedInUser) || getSessionItem(StorageItem.LoggedInUser)
      }
      dispatch(userDobaraSetKr(userNaya));
      setFormData(userNaya)
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (orderID) {
      dispatch(fetchOrderID(orderID));
    }
  })

  const couponClick = () => {
    setCoupon(!coupon);
  }


  const paymentBtn = (event) => {
    event.preventDefault();
    dispatch(postUserOrder({ ...formData, cartId: cartID }))
  }


  const CodBtn = (event) => {
    event.preventDefault();
    dispatch(postCodOrder({ ...formData, cartId: cartID }))
    if (thanku === true) {
      navigate('/Thank-You')
    }
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const couponInputChange = (event) =>
  {
      setCoupon(event.target.value);
  }

  const couponClickk = () =>
  {
      dispatch(fetchCoupon({code: coupon}))
  }
  return (
    <div className="checkout_wrapper">
      <div className="inner-sec-container">
        <div className="checkout_parent_wrapper">
          <Breadcrum item={""} />
          <div className="checkout_content_section mt-5">
            <div className="checkout_returning_customer_section">
              <div className="row">
                <div className="col-lg-12">
                  <div className="returning_customer_area">
                    <div className="returning_customer_content_area">
                      <img src={coupon_icon} alt="return_customer" />
                      <p>Have a coupon?</p>
                      <span onClick={couponClick}>Click here to enter your code</span>

                      {
                        coupon ? <div className="coupon_code_section">
                          <TextField id="outlined-basic" label="Coupon code" variant="outlined" size="small" onChange={couponInputChange}/>
                          <button className='apply_coupon-btn' onClick={couponClickk}>Apply Coupon</button>
                        </div> : ''
                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="billing_detail_section mt-5">
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="billing_detail_left_section">
                      <h2>Billing Details</h2>
                      <div className="row">
                        <div className="col-lg-6 mt-4">
                          <TextField
                            required
                            label="First Name"
                            fullWidth
                            type="text"
                            name="first_name"
                            value={formData?.first_name || formData?.first_name || ''}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <TextField
                            required
                            label="Last Name"
                            fullWidth
                            type="text"
                            name="last_name"
                            value={formData?.last_name || formData?.last_name || ''}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <TextField
                            label="Company Name(optional)"
                            fullWidth
                            type="text"
                            name="company_name"
                            value={formData?.company_name || formData?.company_name || ''}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Country/Region</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                required
                                value={formData?.country || formData?.country || 'Angola'}
                                label="Country/Region"
                                name="country"
                                onChange={handleChange}
                              >
                                {
                                  country?.countries?.map((country, index) => {
                                    return <MenuItem value={country?.name} key={index}>{country?.name}</MenuItem>
                                  })
                                }

                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                        <div className="col-lg-6 mt-4">
                          <TextField
                            required
                            label="Street Address"
                            fullWidth
                            placeholder="House number and street name"
                            type="text"
                            name="street_address"
                            value={formData?.street_address || formData?.street_address || ''}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <TextField
                            required
                            label="Town / City"
                            fullWidth
                            type="text"
                            name="city"
                            value={formData?.city || formData?.city || ''}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <TextField
                            required
                            label="Postcode"
                            fullWidth
                            type="number"
                            name="postal_code"
                            value={formData?.postal_code || formData?.postal_code || ''}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <TextField
                            required
                            label="Phone"
                            fullWidth
                            type="number"
                            name="phone_number"
                            value={formData?.phone_number || formData?.phone_number || ''}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <TextField
                            required
                            label="Email Address"
                            fullWidth
                            type="email"
                            name="email_address"
                            value={formData?.email_address || formData?.email_address || ''}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="your_order_detail_section mt-4">
                  <h4>Your Order</h4>
                  <table className="table">
                    <thead>
                      <tr className="checkout_table_heading_section">
                        <th scope="colSpan">Product</th>
                        <th scope="colSpan">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        cartitem?.map((item, index) => {
                          return <tr key={index}>
                            <td>
                              <span>{item?.title}</span>
                              <span><b>x</b></span>
                              <span><b>{item?.quantity}</b></span>
                            </td>
                            <td colspan="3">
                              <p>£ {item?.item_subtotal}</p>
                            </td>
                          </tr>
                        })
                      }
                      <tr>
                        <td>
                          <p>Subtotal</p>
                        </td>
                        <td>
                          <p>
                            £ {Subtotal}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Total</p>
                        </td>
                        <td>
                          <p className='checkout_total_price'>
                            £ {Subtotal}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="checkout_payment_section mt-4">
                  <div className="checkout_payment_paypal_section">
                    <h3>PayPal</h3>
                    <div className="checkout_payment_payment_section">
                      <img src={payment_img} alt="" />
                    </div>
                    <a href='https://www.paypal.com/uk/webapps/mpp/paypal-popup' target="blank">What is PayPal?</a>
                  </div>
                  <div className="checkout_payment_creditcard_section">
                    <p>Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account</p>
                  </div>
                  <hr />
                  <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                  <div className="checkout_payment_btn_section mt-4">
                    <button className="payment_btn" onClick={CodBtn}>
                      <Link to="/Thank-You">
                        Cash On Delivery
                      </Link>
                    </button>
                    <span className='Or-text'>Or</span>
                    <button className="payment_btn" onClick={paymentBtn}>
                      Proceed to PayPal
                    </button>

                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default Checkout