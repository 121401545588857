
import './Product_Detail.css';
import { AiOutlineTwitter, AiOutlineGooglePlus } from 'react-icons/ai';
import { FaFacebookF, FaPinterestP, FaLinkedinIn } from 'react-icons/fa';
import { BsCartPlus, BsSuitHeart } from 'react-icons/bs';
import { HiPlusSm, HiMinusSm } from 'react-icons/hi';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem, decrement, fetchProductDetail, postCartItem, postFavouriteItem, showCartAlert, showFavAlert } from '../../Store/Slices/Cart_Item';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Reviews from '../../Components/Reviews/Reviews';
import ReactImageMagnify from 'react-image-magnify';


function ProductDetail() {
    const dispatch = useDispatch();
    const item = useSelector((state) => state.cartItem.productDetail?.product);
    let { slug } = useParams();
    const [favoritesData, setFavoritesData] = useState(
        {
            productId: item?._id
        }
    )
    useMemo(() => {
        if (slug) {
            dispatch(fetchProductDetail(slug))
        }
    }, [dispatch, slug]);


    const onCartClick = (item) => {
        const cartData =
        {
            productId: item
        }

        dispatch(postCartItem(cartData));
        dispatch(showCartAlert());
    }

    const addFavouriteItem = () => {
        const id = {
            "productId": favoritesData?.productId
        }
        dispatch(postFavouriteItem(id));
        dispatch(showFavAlert());
    }
    const hideMiddleLetters = (name) => {
        const nameArray = name.split('');
        const middleIndex = Math.floor(name.length / 2);
        const lettersToHide = 4;
        const startIndex = Math.max(0, middleIndex - Math.floor(lettersToHide / 2));
        const endIndex = Math.min(name.length, startIndex + lettersToHide);

        for (let i = startIndex; i < endIndex; i++) {
            nameArray[i] = '*';
        }

        return nameArray.join('');
    };
    const shareOnTwitter = (item) => {
        const tweetText = `Check out this amazing product: ${item?.title}! `;
        const tweetUrl = encodeURIComponent(item?.main_image?.url);
        const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${tweetUrl}`;
        window.open(twitterShareUrl, '_blank');
      };
    return (
        <>
            <div className="product_detail_wrapper">

                <Breadcrum item={item} />
                <div className="product_detail_content_section">
                    <div className="inner-sec-container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="product_detail_left_content_area">
                                    <div className="product_detail_img_section">
                                        <Carousel showArrows={true} swipeable={true} showThumbs={true}>
                                            {
                                                item?.images?.map((itemImg, index) => {
                                                    return <div className='p_detail_img_area' key={index}>
                                                        <ReactImageMagnify {...{
                                                            
                                                            smallImage: {
                                                                alt: 'products_img',
                                                                isFluidWidth: true,
                                                                src: itemImg?.url,
                                                                imageClassName: 'product_img'
                                                            },
                                                            largeImage: {
                                                                src: itemImg?.url,
                                                                width: 1000,
                                                                height: 1000,
                                                                imageClassName: 'product_img'
                                                            },
                                                            enlargedImagePosition: "over",
                                                            isHintEnabled: true
                                                        }} />
                                                    </div>
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="product_detail_right_content_area">
                                    <h2 className='product_title'>{item?.title}</h2>
                                    <div className="rating_section">
                                        <Reviews value={item?.average_rating} className='product_rating_icon' />
                                        <span className='customer_review'>({item?.total_reviews} customer reviews)</span>
                                    </div>
                                    <div className="product_price_section">
                                        <span className='product_price'>£{item?.price}</span>
                                        <strike><span className='discount_price'>£{item?.comparePrice}</span></strike>
                                        <p className={item?.quantity_in_stock ? 'product_available_stock' : 'product_out_of_stock'}>{item?.quantity_in_stock > 0 ? "Available Stock" : "Out of Stock"}</p>
                                    </div>

                                    <div className="product_description">
                                        <h2>Description:</h2>
                                        <ul>
                                            <li>{item?.description}</li>
                                        </ul>

                                    </div>
                                    <div className="product_reviews_section">
                                        <h2>Reviews:</h2>
                                        {
                                            item?.reviews?.length === 0 ? <div>
                                                <p>No Reviews</p>
                                            </div>
                                                :
                                                <div className="product_reviews_content_section">
                                                    {
                                                        item?.reviews?.map((revItem, index) => {
                                                            return <div className="product_reviews_area" key={index}>
                                                                <Reviews value={revItem?.rating} className='product_rating_icon' />
                                                                <p className='user_name'>by {hideMiddleLetters(revItem?.username)}</p>
                                                                <p className='user_comment'>{revItem?.comment}</p>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                        }
                                    </div>
                                    <div className="share_product">
                                        <h2>Share This: </h2>
                                        <div className='social_links_section'>
                                            <div className='social_links_area fb'>
                                                <FaFacebookF />
                                            </div>
                                            <div className='social_links_area twitter' onClick={() => shareOnTwitter(item)}>
                                                <AiOutlineTwitter />
                                            </div>
                                            <div className='social_links_area linkedIn'>
                                                <FaLinkedinIn />
                                            </div>
                                            <div className='social_links_area googlePlus'>
                                                <AiOutlineGooglePlus />
                                            </div>
                                            <div className='social_links_area pinterest'>
                                                <FaPinterestP />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="product_cart_section">
                                        {/* {
                                            item?.quantity > 0 &&
                                            <div className='counter_section'>
                                                <span className='counter_quantity_heading'>Quantity:</span>
                                                <button className='counter_minus' onClick={() => decCounter(item)} disabled={item?.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                                                <span className='counter'>{item?.quantity}</span>
                                                <button className='counter_plus' onClick={() => incCounter(item)}><HiPlusSm className='counter_icon' /></button>
                                            </div>
                                        } */}

                                        <button className='product_cart_add_section' onClick={() => onCartClick(item?._id)}>Add to basket <BsCartPlus /></button>
                                        <button className='product_cart_fav_section' onClick={() => addFavouriteItem()}><BsSuitHeart className='fav_icon' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetail;