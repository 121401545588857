/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import './Product_Listing.css';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { FaListUl } from 'react-icons/fa';
import Vertical_Card from '../../Components/Card/Vertical Card/Vertical_Card';
import { useDispatch, useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import { fetchFilterProduct, fetchShopCategories, fetchShopData } from '../../Store/Slices/Cart_Item';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Pagination, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';

function ProductListing() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.cartItem?.shopData?.products);
    const categories = useSelector((state) => state.cartItem?.shopCategories?.categories);
    const firstProd = useSelector((state) => state.cartItem?.firstProd);

    const filterPrice = useSelector((state) => state.cartItem?.filterPrice);
    const [isGridActive, setisGridActive] = useState(true);
    const [isListActive, setisListActive] = useState(false);
    const [productList, setProductList] = useState({});
    const [sendProductFilter, setsendProductFilter] = useState({});
    const [sort, setSort] = useState(0);
    const [value, setValue] = useState([]);

    const [columns, setColumns] = useState(4);
    const [listView, setListView] = useState(false);
    const [itemPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem) || firstProd?.slice(indexOfFirstItem, indexOfLastItem);
    const location = useLocation();

    const pageChange = (event, value) => {
        setCurrentPage(value);
    };
    const totalPages = Math.ceil(data?.length / itemPerPage);

    useEffect(() => {
        const searchParams = location.search;
        const queryStr = searchParams?.split('=')?.map(val => val)[1]?.replace(/\+/g, ' ');
        if(queryStr) {
            setsendProductFilter({name: queryStr})
            dispatch(fetchShopData({ category: queryStr, sortValue: sort }));
            dispatch(fetchFilterProduct());
            dispatch(fetchShopCategories());
        }
        else {
            dispatch(fetchFilterProduct());
            dispatch(fetchShopCategories());
        }
      }, [dispatch, location.search, sort]);

    useEffect(() => {
        if(categories) {
            setValue([filterPrice.min, filterPrice?.max])
        }
    }, [categories, filterPrice?.max, filterPrice.min])

    const sorting = (event) => {
        const value = event.target.value
        switch (value) {
            case 'new':
                setSort(2)
                setValue([0, filterPrice?.max])
                dispatch(fetchShopData({ category: sendProductFilter.name || productList?.name || firstProd[0]?.category, sortValue: 2  , min: 0 , max: filterPrice?.max}));
                break;
            case 'low price':
                setSort(1)
                setValue([0, filterPrice?.max])
                dispatch(fetchShopData({ category: sendProductFilter.name || productList?.name || firstProd[0]?.category, sortValue: 1  , min: 0 , max: filterPrice?.max}));
                break;
            case 'high price':
                setSort(-1)
                setValue([0, filterPrice?.max])
                dispatch(fetchShopData({ category: sendProductFilter.name || productList?.name || firstProd[0]?.category, sortValue: -1  , min: 0 , max: filterPrice?.max}));
                break;
            default:
                setSort(0)
                setValue([0, filterPrice?.max])
                dispatch(fetchShopData({ category: sendProductFilter.name || productList?.name || firstProd[0]?.category, sortValue: 0  , min: 0 , max: filterPrice?.max}));
                break;
        }
    }

    const listViewClick = (numCol) => {
        setColumns(numCol);
        setListView(true);
        setisGridActive(false);
        setisListActive(true)
    }

    const gridViewClick = (numCol) => {
        setColumns(numCol);
        setListView(false);
        setisGridActive(true);
        setisListActive(false);
    }

    const handleChange = (event, newValue, activeThumb) => {
        setValue(newValue);
    };


    const filter = () => {
        dispatch(fetchShopData({ category: sendProductFilter?.name, sortValue: sort , min: value[0] , max: value[1] }));
    }

    const resetFilter = () => {
        setValue([0, filterPrice?.max])
        setSort(0);
        dispatch(fetchShopData({ category: sendProductFilter?.name, sortValue: sort  , min: 0, max: filterPrice?.max}));
    }

    const productListing = (cate) => {
        dispatch(fetchShopData({ category: cate.name, sortValue: sort  , min: value[0] , max: value[1]}));
        setsendProductFilter(cate)
    }

    return (
        <div className="product_listing_wrapper">
            <div className="inner-sec-container">
                <div className="product_listing_content_section">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="categories_section">
                                <h1 className='categories_heading'>Product Categories</h1>
                                <div className="categories_list_section">
                                    <Accordion flush >

                                        {
                                            categories?.map((item, index) => {
                                                return <Accordion.Item eventKey={index} key={index}>
                                                    <Accordion.Header>{item.parent_category}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul>
                                                            {
                                                                item?.category?.map((cate, i) => {
                                                                    return <li key={i} onClick={() => productListing(cate)}>
                                                                        {cate.name}
                                                                    </li>
                                                                }
                                                                )
                                                            }

                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            })
                                        }

                                    </Accordion>
                                </div>
                                <div className="price_filter_area">
                                    <h2 className='price_filter_heading'>Filter By Price</h2>
                                    <span className='price_filter_label'>Min Price: </span>
                                    <span className='filter_price'>£{value[0]}</span> <br />
                                    <span className='price_filter_label'>Max Price: </span>
                                    <span className='filter_price'>£{value[1]}</span>
                                    <br />
                                    <Box sx={{ width: '100%' }}>
                                        <Slider
                                            onChange={handleChange}
                                            value={value}
                                            valueLabelDisplay="auto"
                                            min={filterPrice?.min}
                                            max={filterPrice?.max}
                                        />
                                      
                                    </Box>
                                    <div className="filter_button_section">
                                        <button className='filter_btn' onClick={() => filter()}>Filter</button>
                                        <button className='reset_filter_btn' onClick={() => resetFilter()}>Reset Filter</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="categories_content_section">
                                <div className="categories_content_header_section">
                                    <div className="list_grid_view_section">
                                        <BsFillGrid3X3GapFill className={isGridActive ? 'grid_icon active' : 'grid_icon'} onClick={() => gridViewClick(4)} />
                                        <FaListUl className={isListActive ? 'list_icon active' : 'list_icon'} onClick={() => listViewClick(12)} />
                                    </div>
                                    <div className="showing_result_area">
                                        <p>Items per page: {currentItems.length}</p>
                                    </div>
                                    <div className="sorting_area">
                                        <select id="sorting_dropdown" onChange={sorting}>
                                            <option value="default">Default Sorting</option>
                                            <option value="new">Sort By New</option>
                                            <option value="low price">Lowest Price</option>
                                            <option value="high price">Highest Price</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="categories_content_area">
                                    <div className={`row row-cols-${columns}`}>
                                        {
                                            currentItems?.length === 0 ? 
                                                <div className="no_product">
                                                <h3>No Products Found <br />  <p>We can't find any product matching your filter.</p></h3>
                                              
                                                </div>
                                             
                                            
                                            :
                                            currentItems?.map((itemm, index) => {
                                                return <div className={`col-lg-${columns} mt-4`} key={index}>
                                                    <Vertical_Card item={itemm} listView={listView} />
                                                </div>
                                            })
                                        }

                                    </div>
                                </div>

                                <div className="pagination_section">

                                    <div className="pagination_area">
                                        <Stack spacing={2}>
                                            <Pagination count={totalPages} page={currentPage} onChange={pageChange} color='primary' />
                                        </Stack>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductListing