import React from 'react'
import './ThankYou.css'
import img from "../../Images/tick.png"
import { Link } from 'react-router-dom'

function ThankYou() {
  return (
   <div className="thanku_wrapper">
    <div className="inner-sec-container">
        <div className="thanku_parent_section"> 
        <div className="thanku_content_area">
        <img src={img} alt="" />
        <h1>THANK YOU!</h1>
        <p>Your order has been successfully submitted!</p>
        <p>Your order number is : <span><b>538345903</b></span></p>
        <p>An email has been sent out to your Order Approver(s) for review</p>
        <button className='continue-shopping-btn'>
        <Link to="/">
        Continue Shopping
        </Link>
           
        </button>
        </div> 
    
        </div>
    </div>
   </div>
  )
}

export default ThankYou