
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './NavList.module.css'
import { Link, useNavigate } from 'react-router-dom';
import { fetchProductDetail, productClick } from '../../Store/Slices/Cart_Item';

function NavList(props) 
{
    const mainCategory = useSelector((state) => state.cartItem?.categories?.products);
    const [hoverData, setHoverData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ProductDetail = (event, item) => {
        event.preventDefault();
        navigate(`/ProductDetail/${item?.slug}`)
        dispatch(fetchProductDetail(item?.slug))
        dispatch(productClick(item?._id))
    }
    return (
        <> 
            <div id='dropdown_parent' className={classes.navList_section}>
                <div className={classes.categories_section} id="categories_section">
                    <h1 className={classes.categories_section_heading}>Categories</h1>
                    <ul  className={classes.categories_list}>
                        {
                            mainCategory?.map((subCat, index) => {
                                return (
                                    props.parentName === subCat?.parent_category && subCat?.category?.map((data, j) => {
                                        return <li key={j} onMouseOver={() => setHoverData(data)}>
                                            <Link className={classes.categoriesList} to={{pathname: '/Product-Listing', search: new URLSearchParams({category: data?.name}).toString()}}>
                                            {data?.name}
                                            </Link>
                                        </li>
                                    })
                                )
                            })
                        }
                    </ul>
                </div>

                <div className={classes.data_section}>
                    <h1 className={classes.categories_section_heading}>Data</h1>

                    <div className="row">
                        {
                            hoverData?.length === 0 ?
                            props?.firstItem?.category[0]?.products?.slice(0,3)?.map((data, i) => {
                                return <>
                                    <div className="col-lg-4 mt-4" key={i}>
                                        <div className={classes.data_card_section}>
                                            <div className={classes.image_section}>
                                                <img src={data.main_image.url} alt="" />
                                            </div>
                                            <div className={classes.data_card_content_section}>
                                            <h4 onClick={(event) => ProductDetail(event, data)}>{data.title}</h4>
                                            <p>£{data.price}</p>
                                            </div>
                                           
                                        </div>  
                                    </div>
                                </>
                            })
                            :
                            hoverData?.products?.slice(0,3)?.map((item, index) => {
                                return <>
                                    <div className="col-lg-4 mt-4" key={index}>
                                        <div className={classes.data_card_section}>
                                            <div className={classes.image_section}>
                                                <img src={item.main_image.url} alt="" />
                                            </div>
                                            <div className={classes.data_card_content_section}>
                                            <h4 onClick={(event) => ProductDetail(event, item)}>{item.title}</h4>
                                            <p>£{item.price}</p>
                                            </div>
                                        </div>  
                                    </div>
                                </>
                            }) 
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavList;