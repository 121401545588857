import React from 'react'
import Classes from './Pagination.module.css'
import { IoIosArrowForward , IoIosArrowBack } from 'react-icons/io';


function Paginate({ totalRecords, recordsPerPage, onPageChange }) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const pages = [];
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }
    const handleClick = page => {
        setCurrentPage(page);
        onPageChange(page);
    };
    return (

        <div className={Classes.pagination_wrapper}>
        <div>
            {currentPage > 1 && (
                <button
                    onClick={() => handleClick(currentPage - 1)}
                    className={Classes.prev_btn}
                >
                    <IoIosArrowBack/>
                </button>
            )}

            {currentPage > 3 && (
                <button
                    onClick={() => handleClick(1)}
                    className={Classes.pageNumber}
                >
                    1
                </button>
            )}

            {currentPage > 4 && <span className={Classes.dots}>......</span>}

            {pages.map(page => {
                if (page === currentPage) {
                    return (
                        <button
                            key={page}
                            onClick={() => handleClick(page)}
                            className={Classes.currentPage}
                        >
                            {page}
                        </button>
                    );
                } else if (page >= currentPage - 1 && page <= currentPage + 1) {
                    return (
                        <button
                            key={page}
                            onClick={() => handleClick(page)}
                            className={Classes.otherPages}
                        >
                            {page}
                        </button>
                    );
                } else {
                    return null;
                }
            })}

            {currentPage < pages.length - 3 && <span className={Classes.dots}>......</span>}

            {currentPage < pages.length - 2 && (
                <button
                    onClick={() => handleClick(pages.length)}
                    className={Classes.otherPages}
                >
                    {pages.length}
                </button>
            )}

            {currentPage < pages.length && (
                <button
                    onClick={() => handleClick(currentPage + 1)}
                    className={Classes.nextBtn}
                >
                    <IoIosArrowForward />
                </button>
            )}
        </div>
        </div>
    )
}

export default Paginate