import React, { useState } from 'react';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import './ReturnPolicy.css';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { postComment } from '../../Store/Slices/Cart_Item';

function ReturnPolicy() {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(
        {
          name : '',
          email: '',
          number: 0,
          text: ''
        }
      )
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(postComment(formData))
    };
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
          });
    };
  return (
    <div className="return_policy_wrapper">
        <div className="inner-sec-container">
            <div className="return_policy_parent_section">
            <Breadcrum item={""}/>
            <div className="return_policy_content_section mt-4">
            <h2>Return Policy</h2>
                <ul>
                    <li>
                        14 day return policy applies from the date the item is delivered, any damage must be reported within 24 hours. Otherwise we will not be able to exchange the item.
                    </li>
                    <li>
                        All exchanges will be made within 4-14 working days basis once item is received.
                    </li>
                    <li>
                        Any unwanted items ordered must be returned at buyers expense and will be charged 10% handling fee.
                    </li>
                    <li>
                        The cancellation period only applies to goods that are unused, 7 day cancellation period applies.
                    </li>
                    <li>
                        If the item has been used we don’t accept returns.
                    </li>
                    <li>
                        If the product is faulty and the fault has been verified,  we will decide to refund or repair at our discretion, as our right under sales of goods act.
                    </li>
                    <li>
                        If you wish to cancel your purchase under distance selling regulations you must notify us in writing through email.
                    </li>
                    <li>
                        The bikes need to be assembled by professional cycle mechanics to keep the warranty, we will not be held responsible for incorrect assembly.
                    </li>
                </ul>
                <div className="leave_your_comment_section mt-4">
                <h3>Leave your comment </h3>
                <div className="contact_us_form_section">
                                        <form onSubmit={handleSubmit}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Name"
                                                type="text"
                                                name='name'
                                                value={formData?.name}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Email"
                                                type="email"
                                                name='email'
                                                value={formData?.email}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Number"
                                                type="number"
                                                name="number"
                                                value={formData?.number}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Message"
                                                multiline
                                                rows={4}
                                                name='text'
                                                value={formData?.text}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <button className="submit_btn" type='submit'>Submit</button>
                                        </form>
                                    </div>
                </div>
                
            </div>
            </div>
        </div>
    </div>
  )
}

export default ReturnPolicy