import classes from './Footer.module.css';
import { AiFillHome } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import { BsTelephoneFill } from 'react-icons/bs';
import paymentImg from '../Images/payment_footer_img.png'
import SocialLinks from '../Components/SocialLinks/SocialLinks';
import { Link } from 'react-router-dom';


function Footer() {
    return (
        <>
            <footer className={classes.footer_wrapper}>
                <div className={classes.inner_sec_container}>
                    <div className={classes.footer_section}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className={classes.about_store_section}>
                                    <h1 className={classes.footer_heading}>About Store</h1>
                                    <div className={classes.footer_content_section}>
                                        <p>
                                            <AiFillHome className={classes.footer_content_icon} />
                                            <span>Unit 15, Osborne Mill Waddington Street Oldham Greater Manchester OL9 6QH</span></p>
                                        <p>
                                            <GrMail className={classes.footer_content_icon} />
                                            <a href='mailto:jammycycles@yahoo.co.uk'>jammycycles@yahoo.co.uk</a></p>
                                        <p>
                                            <BsTelephoneFill className={classes.footer_content_icon} />
                                            <a href='tel:0161 652 3258'>0161 652 3258</a></p>
                                       <img src={paymentImg} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className={classes.useful_links}>
                                    <h1 className={classes.footer_heading}>Useful Links</h1>
                                    <div className={classes.footer_content_section}>
                                        <p>
                                            <Link to="/About-Us">About Us</Link></p>
                                        <p>
                                        <Link to="/Return-Policy">Return Policy</Link>
                                        </p>
                                        <p>
                                        <Link to="/Order-Tracking">Order Tracking</Link></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className={classes.social_links}>
                                    <h1 className={classes.footer_heading}>Social Links</h1>
                                   <SocialLinks/>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <p className={classes.copyright_text}>©2021 ctbikes.co.uk. All Rights Reserved.</p>
                    </div>
                 
                </div>
            </footer>
        </>
    );
}

export default Footer;


