
import React, { useEffect, useMemo, useState } from 'react'
import { BsCartPlus, BsSuitHeart } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProductDetail, postCartItem, postFavouriteItem, productClick, setUserToken, showCartAlert, showFavAlert, showLoginModel } from '../../../Store/Slices/Cart_Item';
import Classes from './Vertical_Card.module.css';
import sm_loader_img from '../../../Images/sm_loader_img.svg';


export default function Card(props) {
    const smLoader = useSelector((state) => state.cartItem.smallLoader);
    const login = useSelector((state) => state.cartItem?.login);
    let item = props.item;
    const navigate = useNavigate();
    const [featureCardHover, setFeatureCardHover] = useState(null);
    const [hover, setHover] = useState(false);
    const Listview = props.listView;
    const dispatch = useDispatch();

    useEffect(() => {
        if (Listview) {
            setHover(false)
        }
        else {
            setHover(true)
        }
    }, [Listview]);

    
    useEffect(() => {
        dispatch(setUserToken())
    }, [dispatch])

    const ProductDetail = (event, item) => {
        event.preventDefault();
        navigate(`/ProductDetail/${item?.slug}`)
        dispatch(fetchProductDetail(item?.slug))
        dispatch(productClick(item?._id))
    }


    const onCartClick = (item) => {
        const cartData  = 
            {
                productId : item
            }
        
        if(login === true)
        {
            
        dispatch(postCartItem(cartData));
            dispatch(showCartAlert());
        }
        else {
            dispatch(showLoginModel())
        }
    }

    const addFavouriteItem = (item) => {
        
        const id = {
            "productId": item
        }

        if(login === true)
        {
            
        dispatch(postFavouriteItem(id));
            dispatch(showFavAlert());
        }
        else{
            dispatch(showLoginModel())
        }
       
    }

    return (
        <>
            <div className={Listview ? `${Classes.card} ${Classes.listview_card} ` : Classes.card} key={item._id} onMouseOver={() => setFeatureCardHover(item._id)} onMouseLeave={() => setFeatureCardHover(null)}>
                <div className={Classes.carousel_img_section}>
                    <img src={item.main_image.url} alt="" />
                </div>
                <div className={Listview ? `${Classes.carousel_content_section} ${Classes.listview_content_section}` : Classes.carousel_content_section}>

                    <h3 className={featureCardHover === item?._id ?  `${Classes.card_hover_title}` : `${Classes.card_title}`} onClick={(event) => ProductDetail(event, item)}>{item?.title}</h3>
                    <p className={featureCardHover === item?._id ? `${Classes.card_hover_price}` : ''}>£ {item.price}</p>

                    {
                        Listview ? <>
                            <div className={Classes.list_view_section}>
                                <p>{item.description}</p>
                                <div className="product_cart_section">
                                    {/* {
                                        item.quantity > 0 && <div className='counter_section'>
                                            <span className='counter_quantity_heading'>Quantity:</span>
                                            <button className='counter_minus' onClick={() => decCounter(item)} disabled={item.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                                            <span className='counter'>{item.quantity}</span>
                                            <button className='counter_plus' onClick={() => incCounter(item)}><HiPlusSm className='counter_icon' /></button>
                                        </div>
                                    } */}

                                    <button className='product_cart_add_section'  onClick={() => onCartClick(item?._id)}>Add to basket <BsCartPlus /></button>
                                    <button className='product_cart_fav_section' onClick={() => addFavouriteItem(item?._id)}><BsSuitHeart className='fav_icon' /></button>
                                </div>
                            </div>

                            <button className={Classes.product_detail_btn} onClick={(event) => ProductDetail(event, item)}>Details</button>
                        </> : ''
                    }
                </div>
                {featureCardHover === item?._id && hover === true ?
                    <>
                        <div className={Classes.cards_hover_section}>
                            <div className={smLoader ? Classes.sm_loader_hover : Classes.shopping_cart_btn} onClick={() => onCartClick(item?._id)}>
                            {
                                smLoader ? <div className={Classes.sm_loader_section}>
                                                    <img src={sm_loader_img} alt="" />
                                                    </div> :  <BsCartPlus className={Classes.cards_hover_icon} />
                            }
                           
                            </div>
                            <div className={smLoader ? Classes.sm_loader_hover : Classes.favourite_btn} onClick={() => addFavouriteItem(item?._id)}>
                            {
                                smLoader ? <div className={Classes.sm_loader_section}>
                                                    <img src={sm_loader_img} alt="" />
                                                    </div> :  <BsSuitHeart className={Classes.cards_hover_icon} />
                            }
                            </div>
                        </div>
                    </>
                    : ''}


            </div>
        </>
    )
}
