import React, { useEffect, useState } from 'react';
import Classes from './Horizontal_Card.module.css'
import { AiTwotoneStar } from 'react-icons/ai';
import { BsCartPlus, BsSuitHeart } from 'react-icons/bs';
import { addCartItem, addFavourites, fetchProductDetail, postCartItem, postFavouriteItem, productClick, setUserToken, showCartAlert, showFavAlert, showLoginModel } from '../../../Store/Slices/Cart_Item';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Reviews from '../../Reviews/Reviews';
import sm_loader_img from '../../../Images/sm_loader_img.svg';

function Horizontal_Card(props) {
    const smLoader = useSelector((state) => state.cartItem.smallLoader);
    let items = props.item;
    const [featureCardHover, setFeatureCardHover] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();  
    const login = useSelector((state) => state.cartItem?.login);
    const [favoritesData , setFavoritesData] = useState(
        {
            productId : items?._id
        }
    )
    
    useEffect(() => {
        dispatch(setUserToken())
    }, [dispatch])
    const onCartClick = (item) => {
        const cartData  = 
            {
                productId : item
            }
      
        if(login === true)
        {
              
            dispatch(postCartItem(cartData));
            dispatch(showCartAlert());
        }
        else {
            dispatch(showLoginModel())
        }
    }

    const addFavouriteItem = () => {
        const id = {
            "productId": favoritesData?.productId
        }
      
        if(login === true)
        {
            dispatch(postFavouriteItem(id));
            dispatch(showFavAlert());
        }
        else{
            dispatch(showLoginModel())
        }
    }
    
    const ProductDetail = (event, item) => {
        event.preventDefault();
        navigate(`/ProductDetail/${item?.slug}`)
        dispatch(fetchProductDetail(item?.slug))
        dispatch(productClick(item?._id))
    }
    return (
        <div className={Classes.product_card_section} key={items._id} onMouseOver={() => setFeatureCardHover(items._id)} onMouseLeave={() => setFeatureCardHover(null)}>
            <div className="row">
                <div className="col-lg-6">
                    <div className={Classes.product_card_img_section}>
                        <img src={items.main_image.url} alt="" />
                    </div>

                </div>
                <div className="col-lg-6">
                    <div className={Classes.product_card_content_section}>
                        <h4 className={Classes.CardHoverTitle} onClick={(event) => ProductDetail(event, items)}>{items.title}</h4>
                        <div className={Classes.product_rating_section}>
                        <Reviews value={items?.average_rating} className={Classes.product_rating_icon}/>
                        </div>
                        <p>£ {items.price}</p>
                    </div>
                </div>
            </div>
            {featureCardHover === items._id && 
                    <>
                        {/* <div className={Classes.cards_hover_section}>
                            <div className={Classes.shopping_cart_btn} onClick={() => onCartClick(items?._id)}><BsCartPlus className={Classes.cards_hover_icon} /></div>
                            <div className={Classes.favourite_btn} onClick={() => addFavouriteItem(items?._id)}><BsSuitHeart className={Classes.cards_hover_icon} /></div>
                        </div> */}

                        <div className={Classes.cards_hover_section}>
                            <div className={smLoader ? Classes.sm_loader_hover : Classes.shopping_cart_btn} onClick={() => onCartClick(items?._id)}>
                            {
                                smLoader ? <div className={Classes.sm_loader_section}>
                                                    <img src={sm_loader_img} alt="" />
                                                    </div> :  <BsCartPlus className={Classes.cards_hover_icon} />
                            }
                           
                            </div>
                            <div className={smLoader ? Classes.sm_loader_hover : Classes.favourite_btn} onClick={() => addFavouriteItem(items?._id)}>
                            {
                                smLoader ? <div className={Classes.sm_loader_section}>
                                                    <img src={sm_loader_img} alt="" />
                                                    </div> :  <BsSuitHeart className={Classes.cards_hover_icon} />
                            }
                            </div>
                        </div>
                    </>
                       
                  
                    }
        </div>
    )
}

export default Horizontal_Card