import { TextField } from '@mui/material'
import React, { useState } from 'react'
import Breadcrum from '../../../Components/BreadCrum/Breadcrum'
import './ResetPassword.css'
import { useDispatch } from 'react-redux'
import { postForgetPassword } from '../../../Store/Slices/Cart_Item'

function ResetPassword() {
 const dispatch = useDispatch();
 const [formData, setFormData] = useState(
  {
    email_address: ''
  }
)
  const sendEmail = (event) => 
  {
    event.preventDefault();
    dispatch(postForgetPassword(formData))
  }

  
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

  };
  return (
    <div className="resetpassword_wrapper">
        <div className="inner-sec-container">
            <div className="resetpassword_parent_section">
                <Breadcrum item={""} />
                <div className="resetpassword_content_section mt-5">
                    <div className="resetpassword_content_area">
                    <p>Lost your password? Please enter your email address. You will receive a link to create a new password via email.</p>
                    <form>
                        <TextField
                        required
                        label="Email"
                        fullWidth
                        type="email"
                        placeholder="Please enter your email address"
                        margin="normal"
                        value={formData.email_address}
                        name='email_address'
                        onChange={handleChange}
                      />
                      <button className='resetPassword-btn' onClick={sendEmail}>Reset Password</button>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResetPassword