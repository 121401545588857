import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api_Service from "../../Api Handling/api-service";
import { StorageItem, getItem, getSessionItem, removeItem, setItem, setSessionItem } from "../../Utilis/local-storage.utils";

const api = new Api_Service();
const initialState =
{
    items: [],
    productDetail: {},
    value: 0,
    tPrice: 0,
    cartItems:{},
    bestSellers: [],
    popBikeHelmet: [],
    popBikeAcc: [],
    latestProduct: [],
    mostViewed: [],
    topRated: [],
    favourites: {},
    cartAlert: false,
    favAlert: false,
    loginAlert: false,
    categories: [],
    filterPrice: {},
    searchProducts: [],
    firstProd: [],
    shopCategories: [],
    shopData: [],
    userData: {},
    signInData: {data: {}, status: 'idle'},
    signUp: false,
    hideSignup: false,
    userOrder: [],
    search: '',
    homeSlider: [],
    isLoading : false,
    login: false,
    country: {},
    smallLoader : false,
    loginModel: false,
    orderID: null,
    mujyYaddRakhna : false,
    coupon:'',
    userOrders: {},
    userOrderDetail: {},
    thanku: false,
    emailMsg: false
}


export const fetchFeatureProducts = createAsyncThunk('featuredProducts',
    async () => {
        return await api.get(`product/featured`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);


export const fetchBestSellers = createAsyncThunk('bestSellers',
    async () => {
        return await api.get(`product/best-seller`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchpopBikeHelmets = createAsyncThunk('popBikeHelmets',
    async () => {
        return await api.get(`product/bike-accessories`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchpopBikeAcc = createAsyncThunk('popBikeAccessories',
    async () => {
        return await api.get(`product/bike-accessories`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchmostViewed= createAsyncThunk('mostViewed',
    async () => {

        return await api.get(`product/views`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchtopRated= createAsyncThunk('topRated',
    async () => {
       return await api.get(`product/top-rated`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchCategories = createAsyncThunk('categories',
    async () => {
        return await api.get(`product/navbar`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchShopCategories = createAsyncThunk('ShopCategories',
    async () => {
        return await api.get(`product/categories`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchShopData = createAsyncThunk('ShopData',
    async (data) => {
        const {category, sortValue, min, max} = data;
        return await api.get(`product/product-category?category=${category}&sortValue=${sortValue}&min=${min}&max=${max}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })
    }
);

export const fetchProductDetail = createAsyncThunk('fetchProductDetail',
    async (data) => {
        return await api.get(`product/slug/${data}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchlatestProduct= createAsyncThunk('latestProduct',
    async () => {
        return await api.get(`product/latest`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchFilterProduct= createAsyncThunk('filter',
    async () => {
        return await api.get(`product/min-maxProduct`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchSearchProducts = createAsyncThunk('search',
    async (text) => {
        return await api.get(`product/search?text=${text}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const postUserData = createAsyncThunk('userData',
    async (payload) => {
        return await api.post(`user/signup`, payload).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const postSignIn = createAsyncThunk('signInData',
    async (payload) => {
        return await api.post(`user/login`, payload).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const postUserOrder = createAsyncThunk('userOrder',
    async (payload) => {
        return await api.post(`order`, payload).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const postCodOrder = createAsyncThunk('postCodOrder',
    async (payload) => {
        return await api.post(`order`, payload).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const postForgetPassword = createAsyncThunk('forgetPassword',
    async (data) => {
        return await api.post(`user/forget-password`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchOrderID = createAsyncThunk('fetchOrderID',
    async (id) => {
        return await api.get(`payment/pay/${id}`).then(res => {
            if(!res.hasErrors()) {
                if (res.status === 200) {
                    console.log(res.data)
                    window.location = res.data.forwardLink
                } else {
                    throw res.errors[0].err?.text
                }
                return res.data
            }
            else {
                console.log(res.errors[0].err?.text)
                throw res.errors[0].err?.text
            }
          
        })

    }
);
export const postResetPassword = createAsyncThunk('resetPassword',
    async (data) => {
        return await api.post(`user/reset-password`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const postFavouriteItem = createAsyncThunk('favourites',
    async (data) => {
        return await api.post(`wishlist`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const fetchFavouriteItem = createAsyncThunk('fetchFavourites',
    async () => {
        return await api.get(`wishlist`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const deleteFavItem = createAsyncThunk('Deletefavourites',
    async (data) => {
        return await api.put(`wishlist`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const postCartItem = createAsyncThunk('postCartItems',
    async (data) => {
        return await api.post(`cart`, data ).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchCartItem = createAsyncThunk('fetchCartItems',
    async () => {
        return await api.get(`cart`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const deleteCartItem = createAsyncThunk('deleteCartItems',
    async (data) => {
        return await api.patch(`cart/remove-item/${data}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const updateCartItem = createAsyncThunk('updateCartItem',
    async (data) => {
        return await api.patch(`cart`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const fetchHomeSlider = createAsyncThunk('homeSlider',
    async () => {
        return await api.get(`slider`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const productClick = createAsyncThunk('productClick',
    async (id) => {
        return await api.put(`product/click/${id}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchCountry = createAsyncThunk('fetchCountry',
    async () => {
        return await api.get(`country`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const fetchCoupon = createAsyncThunk('fetchCoupon',
    async (data) => {
        return await api.post(`coupon/apply-coupon`, data).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const postComment = createAsyncThunk('postComment',
    async (payload) => {
        return await api.post(`comment`, payload).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);
export const fetchUserOrders = createAsyncThunk('fetchUserOrders',
    async (id) => {
        return await api.get(`order/user/${id}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const fetchUserOrderDetail = createAsyncThunk('fetchUserOrderDetail',
    async (id) => {
        return await api.get(`order/${id}`).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

export const updateUserInfo = createAsyncThunk('updateUserInfo',
    async (data) => {
        const{id , formdata} = data
        console.log("formdata", formdata)
        console.log("id", id)
        return await api.put(`user/${id}`,formdata).then(res => {
            if(!res.hasErrors()) {
                return res.data
            }
            else {
                throw res.errors[0].err?.text
            }
        })

    }
);

const Cart_Item = createSlice({
    name: 'cartItem',
    initialState,
    reducers:
    {
        showCartAlert: (state , action) => 
        {
            state.cartAlert = true;
        },
        hideCartAlert: (state , action) => 
        {
            state.cartAlert = false;
        },
        showFavAlert: (state , action) => 
        {
            state.favAlert = true;
        },
        hideFavAlert: (state , action) => 
        {
            state.favAlert = false;
        },
        hideLoginAlert: (state , action) => 
        {
            state.loginAlert = false;
        },

        addUserData: (state , action) => 
        {
            state.userData = action.payload;
        },
        searchProduct: (state , action) => 
        {
            state.search = action.payload;
        },
        logout: (state, action) => 
        {
            removeItem(StorageItem.JwtToken)
            removeItem(StorageItem.LoggedInUser)
            state.login = false;
        },
        showLoginModel: (state , action) => 
        {
            state.loginModel = true;
        },
        hideSignUpAlert: (state , action) => 
        {
            state.hideSignup = false;
        },
        
        hideStatus: (state , action) => 
        {
            state.signUp = false;
        },

        hideLoginModel: (state , action) => 
        {
            state.loginModel = false;
            state.smallLoader = false;
        },
        hideEmailModel: (state , action) => 
        {
            state.emailMsg = false;
        },
        teriYadein : (state, action) =>
        {
            state.mujyYaddRakhna = action.payload;
        },

        userDobaraSetKr: (state, action) => {
            state.userData = action?.payload;
        },
        setUserToken:(state) => {
            let item = getItem(StorageItem.JwtToken) || getSessionItem(StorageItem.JwtToken);
            if(item) {
                state.login = true
            }
        }


    },
    extraReducers: (builder) => {
        
        builder.addCase(fetchFeatureProducts.fulfilled, (state, action) => {
            state.items = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchFeatureProducts.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchBestSellers.fulfilled, (state, action) => {
            state.bestSellers = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchBestSellers.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchpopBikeHelmets.fulfilled, (state, action) => {
            state.popBikeHelmet = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchpopBikeHelmets.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(fetchpopBikeAcc.fulfilled, (state, action) => {
            state.popBikeAcc = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchpopBikeAcc.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(fetchlatestProduct.fulfilled, (state, action) => {
            state.latestProduct = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchlatestProduct.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchmostViewed.fulfilled, (state, action) => {
            state.mostViewed = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchmostViewed.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchtopRated.fulfilled, (state, action) => {
            state.topRated = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchtopRated.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchProductDetail.fulfilled, (state, action) => {
            state.productDetail = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchProductDetail.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.firstProd = action.payload?.products[0]?.category[0]?.products;
            state.isLoading = false;
        })

        builder.addCase(fetchCategories.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchFilterProduct.fulfilled, (state, action) => {
            state.filterPrice = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchFilterProduct.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchSearchProducts.fulfilled, (state, action) => {
            state.searchProducts = action.payload;
        })
        builder.addCase(fetchShopCategories.fulfilled, (state, action) => {
            state.shopCategories = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchShopCategories.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchShopData.fulfilled, (state, action) => {
            state.shopData = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchShopData.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(postUserData.fulfilled, (state, action) => {
            state.userData = action.payload;
            state.signUp = true;
            state.hideSignup = true;
        })

        builder.addCase(postSignIn.fulfilled, (state, action) => {
            if(state.mujyYaddRakhna === true)
            {
                setItem(StorageItem.JwtToken, action?.payload?.token)
                setItem(StorageItem.LoggedInUser, action?.payload)
            }
            else
            {
                setSessionItem(StorageItem.JwtToken, action?.payload?.token)
                setSessionItem(StorageItem.LoggedInUser, action?.payload)
            }
            state.userData = action.payload;
            state.signInData.status = 'success';
            state.loginAlert = true;
            state.login = true;
            state.loginModel = false;
            state.smallLoader = false;
            
        })

        builder.addCase(postSignIn.rejected, (state, action) => {
            state.userData = action.payload;
            state.signInData.status = 'idle';
        })
        builder.addCase(postUserOrder.fulfilled, (state, action) => {
            state.userOrder = action.payload;
            state.orderID = action.payload.order;
            state.isLoading = false;
        })
        builder.addCase(postUserOrder.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(postCodOrder.fulfilled, (state, action) => {
            state.thanku = true;
            state.isLoading = false;
        })
        builder.addCase(postCodOrder.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchOrderID.fulfilled, (state, action) => {
            state.orderID = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchOrderID.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(postFavouriteItem.fulfilled, (state, action) => {
            state.favourites = action.payload;
            state.smallLoader = false;
        })
        builder.addCase(postFavouriteItem.pending, (state, action) => {
            state.smallLoader = true;
            
        })
        builder.addCase(fetchFavouriteItem.fulfilled, (state, action) => {
            state.favourites = action.payload;
            state.isLoading = false;
            
        })
        builder.addCase(fetchFavouriteItem.pending, (state, action) => {
            state.isLoading = true;
            
        })
        builder.addCase(deleteFavItem.fulfilled, (state, action) => {
          state.favourites = action.payload;

        })
        builder.addCase(postCartItem.fulfilled, (state, action) => {
            state.cartItems = action.payload;
            state.smallLoader = false;
        })
        builder.addCase(postCartItem.pending, (state, action) => {
            state.smallLoader = true;
        })

        builder.addCase(fetchCartItem.fulfilled, (state, action) => {
            state.cartItems = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchCartItem.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(deleteCartItem.fulfilled, (state, action) => {
            state.cartItems = action.payload;
            state.isLoading = false;
        })
        builder.addCase(deleteCartItem.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(updateCartItem.fulfilled, (state, action) => {
            state.cartItems = action.payload;
            state.smallLoader = false;
        })
        builder.addCase(updateCartItem.pending, (state, action) => {
            state.smallLoader = true;
        })
        builder.addCase(fetchHomeSlider.fulfilled, (state, action) => {
            state.homeSlider = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchHomeSlider.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchCountry.fulfilled, (state, action) => {
            state.country = action.payload;
            state.isLoading = false;
        })
        builder.addCase(fetchCountry.pending, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchCoupon.fulfilled, (state, action) => {
            state.coupon = action.payload;
        })
        builder.addCase(postComment.rejected, (state, action) => {
            state.isLoading = true;
        })
        builder.addCase(fetchUserOrders.fulfilled, (state, action) => {
            state.userOrders = action.payload;
        })
        builder.addCase(fetchUserOrderDetail.fulfilled, (state, action) => {
            state.userOrderDetail = action.payload;
        })
        builder.addCase(updateUserInfo.fulfilled, (state, action) => {
            
        })
        builder.addCase(postForgetPassword.fulfilled, (state, action) => {
            state.emailMsg = true;
        })
        

    }
})


export const {hideEmailModel, showCartAlert , hideCartAlert, hideFavAlert, showFavAlert , addUserData , hideLoginAlert, searchProduct, logout, showLoginModel, hideLoginModel, hideSignUpAlert, teriYadein, hideStatus, userDobaraSetKr, setUserToken} = Cart_Item.actions;
export default Cart_Item;