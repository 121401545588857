import React, { useState } from 'react'
import './CreatePassword.css'
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material'
import { useDispatch } from 'react-redux';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { postResetPassword } from '../../../../Store/Slices/Cart_Item';
import { useLocation } from 'react-router-dom';

function CreatePassword() {
  const dispatch = useDispatch(); 
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  // console.log("token")
  const [formData, setFormData] = useState(
    {
      password: '',
      token: token
    }
  )
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postResetPassword(formData));
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

  };
  return (
    <div className="create_password_wrapper">
      <div className="inner-sec-container">
        <div className="create_password_section">
          <div className="create_password_content_section mt-5">
            <div className="create_password_content_area">
              <h2>Create new password</h2>
              <p>Your new password must be different from previous used passwords.</p>
              <form onSubmit={handleSubmit}>
                <FormControl sx={{ mt: 2, width: '100%' }} variant="outlined">
 
                  <TextField
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required
                    label="Password"
                    placeholder="Please enter your password"
                    margin="normal"
                    value={formData.password}
                    name='password'
                    onChange={handleChange}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>


                <button className="reset_passeord_btn" type='submit'>Reset Password</button>
              </form>
            </div>
          </div>
          </div>
          </div>
   
    </div>

  )
}

export default CreatePassword