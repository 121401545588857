import { getItem, getSessionItem, StorageItem } from "../Utilis/local-storage.utils"
import { headersConfig } from "../Api Handling/api-service";

const setHeaders = (token) => {
    const header = {
      ...headersConfig,
      'x-access-token': token,
      'Content-Type': 'application/json'
    };
    return new Headers(header);
  }

const { fetch: originalFetch } = window;

export const ServerInterceptor = () => {
    window.fetch = async (...args) => {
        let [resource, config ] = args;
        const token = getItem(StorageItem.JwtToken) || getSessionItem(StorageItem.JwtToken);
        let options;
        if(token) {
            options = {
                params: config?.params,
                headers: setHeaders(token),
                method: config?.method,
                body: config?.body
            };
        }
        const response = await originalFetch(resource, options || config);
        return response;
    }
} 