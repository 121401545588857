import { Rating, Stack } from '@mui/material'
import React from 'react'

function Reviews(props) {
    const val = props.value;
  return (
  <Rating name="read-only" value={val} readOnly />
  )
}

export default Reviews