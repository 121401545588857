import React, { useState } from 'react';
import classes from './Cart_Card.module.css';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import { deleteCartItem, updateCartItem } from '../../../Store/Slices/Cart_Item';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import sm_loader_img from '../../../Images/sm_loader_img.svg';

function CartCard() {

    const cart = useSelector((state) => state.cartItem.cartItems?.cart?.items);
    const smLoader = useSelector((state) => state.cartItem.smallLoader);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [itemToDelete, setitemToDelete] = useState("");

    const handleClickOpen = (item) => {
        setOpen(true);
        setitemToDelete(item)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const incCounter = (item) => {
        // dispatch(addCartItem(item));
        const updateData = 
        {
            productId : item.productId,
            quantity: item.quantity + 1
        }
        dispatch(updateCartItem(updateData))
    
    }

    const decCounter = (item) => {
        const updateData = 
        {
            productId : item.productId,
            quantity: item.quantity - 1
        }
        dispatch(updateCartItem(updateData))
    }

    const deleteItem = () => {
        dispatch(deleteCartItem(itemToDelete));
        setOpen(false);
    }


    return (
        <>
            <div className={classes.card_card_section}>
                {
                    cart?.length === 0 ? (<div className={classes.empty_list_section}>
                        <h3>Your basket is currently empty.</h3>
                    </div>) : 
                    cart?.map((item) => {
                            return <div className={classes.cart_card_area}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className={classes.cart_card_img_area}>
                                            <img src={item?.main_image?.url} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className={classes.cart_card_desc}>
                                            <h5>{item?.title}</h5>

                                            <div className={classes.cart_card_price_section}>
                                                <p>{item?.price} <span>x</span> <span>{item?.quantity}</span></p>
                                                <div className={classes.counter_section}>

                                                {
                                                    smLoader ? <div className={classes.sm_loader_section}>
                                                    <img src={sm_loader_img} alt="" />
                                                    </div> :   <>
                                                    <button className={classes.counter} onClick={() => decCounter(item)} disabled={item?.quantity === 1}><span>-</span></button>
                                                    <button className={classes.counter}>{item?.quantity}</button>
                                                    <button className={classes.counter} onClick={() => incCounter(item)} ><span>+</span></button>
                                                    </>
                                                }
                                                  
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className={classes.cart_card_remove}>
                                            <RiDeleteBinLine className={classes.delete_icon} onClick={() => handleClickOpen(item?.productId)} />
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    {"Are you sure you want to remove this item in your shopping cart?"}
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>No</Button>
                                                    <Button onClick={deleteItem} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
               
                }

            </div>
        </>
    );
}

export default CartCard;