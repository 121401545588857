import React, { useState } from 'react';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import './ContactUs.css';
import { ImLocation2 } from 'react-icons/im'
import { GrMail } from 'react-icons/gr'
import { FaPhoneAlt } from 'react-icons/fa'
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { postComment } from '../../Store/Slices/Cart_Item';

function ContactUs() {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(
        {
          name : '',
          email: '',
          number: '',
          text: ''
        }
      )
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(postComment(formData))
    };
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
          });
    };
    return (
        <div className="contact_us_wrapper">
            <div className="inner-sec-container">
                <div className="contact_us_parent_section">
                    <Breadcrum item={""} />
                    <div className="contact_us_content_section mt-4">
                        <div className="contact_us_desc_section mt-5">
                            <h2>Get in touch with us</h2>
                            <p>We possess within us two minds. So far I have written only of the conscious mind. I would now like to introduce you to your second mind, the hidden and mysterious subconscious. Our subconscious mind contains such power and complexity</p>
                        </div>
                        <div className="contact_us_map_section mt-5">
                            <iframe
                                title="Google Maps"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.5725093943606!2d-2.133359634887283!3d53.54754681732347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb9d927577a4f%3A0x37a998cea11b6a7a!2sctbikes.co.uk!5e0!3m2!1sen!2s!4v1678785871492!5m2!1sen!2s"
                                width="100%"
                                height="100%"
                                style={{ border: 0 }}
                                aria-hidden="false"
                            ></iframe>
                        </div>
                        <div className="contact_us_info_section mt-5">
                            <div className="row">
                                <div className="col-lg-7">
                                <div className="contact_us_form_section">
                                        <form onSubmit={handleSubmit}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Name"
                                                type="text"
                                                name='name'
                                                value={formData?.name}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Email"
                                                type="email"
                                                name='email'
                                                value={formData?.email}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="Number"
                                                type="number"
                                                name="number"
                                                value={formData?.number}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                id="outlined-multiline-static"
                                                label="Message"
                                                multiline
                                                rows={4}
                                                name='text'
                                                value={formData?.text}
                                                className="form_input_field"
                                                onChange={handleChange}
                                            />
                                            <button className="submit_btn" type='submit'>Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="contact_us_info_area">
                                        <h3>information</h3>
                                        <p><ImLocation2 className='contact_us_icon loc' />
                                            Unit 15, Osborne Mill Waddington Street Oldham Greater Manchester OL9 6QH</p>
                                        <p><GrMail className='contact_us_icon mail' />
                                            jammycycles@yahoo.co.uk</p>
                                        <p><FaPhoneAlt className='contact_us_icon phone' />
                                            0161 652 3258</p>
                                        <h3>Business Hours</h3>
                                        <p>Monday - Thursday : 10.30am - 6.30 pm</p>
                                        <p>Friday : 10.30am - 12pm and 3pm</p>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactUs