import React, { useEffect, useState } from 'react';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import './Favourites.css';
import { useDispatch, useSelector } from 'react-redux';
import { addCartItem, decrement, deleteFavItem, deleteFavourites, fetchFavouriteItem, fetchProductDetail, postCartItem, putFavouriteItem, showCartAlert } from '../../Store/Slices/Cart_Item';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { BsCartPlus } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { StorageItem, getItem } from '../../Utilis/local-storage.utils';
import Paginate from '../../Components/Pagination/Pagination';

function Favourites() {
    const favItem = useSelector((state) => state.cartItem?.favourites?.wishlist?.products);
    const [featureCardHover, setFeatureCardHover] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [deleteFavID, setDeleteFavID] = useState("");
    const [wishListpage, setWishListpage] = React.useState(1);
    const wishitemPerPage = 3;

    useEffect(() => {
        if(open === false)
        {
            dispatch(fetchFavouriteItem());
        }

    }, [dispatch, open])

    const handleClickOpen = (item) => {
        setOpen(true);
        setDeleteFavID(item)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addToBasket = (item) => {
        const cartData  = 
            {
                productId : item
            }
        
        dispatch(postCartItem(cartData));
        if(getItem(StorageItem.JwtToken))
        {
            dispatch(showCartAlert());
            
        }
    }

    const deleteItem = () => {
        const id = 
        {
            productId : deleteFavID
        }
        dispatch(deleteFavItem(id));
        setOpen(false);

    }

    const ProductDetail = (event, item) => {
        event.preventDefault();
        navigate(`/ProductDetail/${item?.slug}`)
        dispatch(fetchProductDetail(item?.slug))
    }

    
  const wishlistPageChange = (page) => {
    setWishListpage(page);
  };
  const wishListpageData = favItem?.slice((wishListpage - 1) * wishitemPerPage, wishListpage * wishitemPerPage);

    return (
        <div className="favourtie_wrapper">
            <div className="inner-sec-container">
                <div className="favourite_parent_section">
                    <Breadcrum item={""} />
                    <div className="favourite_content_section">
                        <table className="table">
                            <thead>
                                <tr className='table_product_heading_section'>
                                    <th scope="colSpan" className='table_products'>Product</th>
                                    <th scope="colSpan" className='table_price'>Price</th>
                                    <th scope="colSpan" className='table_stock'>Stock Status</th>
                                    <th scope="colSpan" className='table_cart_btn'></th>
                                    <th scope="colSpan" className='table_cross_icon'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    wishListpageData?.length === 0 ? (
                                        <tr>
                                            <td colSpan="5">
                                                <div className="empty_list_section">
                                                    <h3 className='empty_list_text'>No products were added to the wishlist</h3>
                                                </div>
                                            </td>

                                        </tr>

                                    ) :
                                        (wishListpageData?.map((fav, index) => {
                                            return <tr key={index}>
                                                <td className='table_products'>
                                                    <div className="table_product_section">
                                                        <div className="table_product_img_section">
                                                            <img src={fav?.main_image?.url} alt="" />
                                                        </div>
                                                        <div className="table_product_title_section">
                                                            <h2  className="card_title" onClick={(event) => ProductDetail(event, fav)}>{fav?.title} </h2>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='table_price'>
                                                    <div className="table_product_price_section">
                                                        <h3>£ {fav?.price}</h3>
                                                    </div>
                                                </td>
                                                <td className='table_stock'>
                                                    <div className="table_product_stock_section">
                                                    <p className={fav?.quantity_in_stock ? 'product_available_stock' : 'product_out_of_stock'}>{fav?.quantity_in_stock > 0 ? "Available Stock" : "Out of Stock"}</p>
                                                    </div>
                                                </td>
                                                <td className='table_cart_btn'>
                                                    <div className="table_cart_section">

                                                        {fav?.quantity > 0 && <div className='counter_section'>
                                                            <span className='counter_quantity_heading'>Quantity:</span>
                                                            <button className='counter_minus'  disabled={fav?.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                                                            <span className='counter'>{fav?.quantity}</span>
                                                            <button className='counter_plus'><HiPlusSm className='counter_icon' /></button>
                                                        </div>
                                                        }

                                                        <button className='product_cart_add_section' onClick={() => addToBasket(fav?._id)}>Add to basket <BsCartPlus /></button>

                                                    </div>
                                                </td>
                                                <td className='table_cross_icon'>
                                                    <div className="table_cross_icon_section">
                                                        <RxCross2 className='table_cross_icon' onClick={() => handleClickOpen(fav?._id)}/>
                                                        <Dialog
                                                            open={open}
                                                            onClose={handleClose}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                {"Are you sure you want to remove this item in your whishlist?"}
                                                            </DialogTitle>
                                                            <DialogActions>
                                                                <Button onClick={handleClose}>No</Button>
                                                                <Button onClick={deleteItem} autoFocus>
                                                                    Yes
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                        )
                                }

                            </tbody>
                        </table>
                        <Paginate totalRecords={favItem?.length} recordsPerPage={wishitemPerPage} onPageChange={wishlistPageChange} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Favourites