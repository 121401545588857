/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFavItem, fetchCountry, fetchFavouriteItem, fetchProductDetail, fetchUserOrderDetail, fetchUserOrders, fetchUserSingleOrders, logout, postCartItem, postForgetPassword, showCartAlert, updateUserInfo, userDobaraSetKr } from '../Store/Slices/Cart_Item';
import { useNavigate } from 'react-router-dom';
import './UserDashboard.css';
import logoImage from '../Images/logo.png';
import { StorageItem, getItem, getSessionItem } from '../Utilis/local-storage.utils';
import { HiMinusSm, HiPlusSm } from 'react-icons/hi';
import { RxCross2 } from 'react-icons/rx';
import { Box, Dialog, DialogActions, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Button } from 'bootstrap';
import { BsCartPlus } from 'react-icons/bs';
import { BiUser } from 'react-icons/bi';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import { GoSignOut } from 'react-icons/go';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import Paginate from '../Components/Pagination/Pagination';

function UserDashboard() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 5; // Set the number of records per page
  const [wishListpage, setWishListpage] = React.useState(1);
  const wishitemPerPage = 3;
  const userOrder = useSelector((state) => state.cartItem?.userOrders?.orders);
  const orderDetails = useSelector((state) => state.cartItem?.userOrderDetail?.order);
  const userID = useSelector((state) => state.cartItem?.userData?.user?.userId);
  const favItem = useSelector((state) => state.cartItem?.favourites?.wishlist?.products);
  let user = useSelector((state) => state.cartItem?.userData?.user?.user);
  const country = useSelector((state) => state.cartItem?.country);
  const [formData, setFormData] = useState(user);
  const [open, setOpen] = useState(false);
  const [deleteFavID, setDeleteFavID] = useState("");
  const [selectedContent, setSelectedContent] = useState('dashboard');
  const [passwordformData, setPasswordFormData] = useState(
    {
      email_address: ''
    }
  )
  const handleItemClick = (content) => {
    setSelectedContent(content);
  };
  const userLogout = () => {
    dispatch(logout());
    navigate('/');
  }
  useEffect(() => {
    if (user) {
      setFormData(user)
      dispatch(fetchCountry());
      dispatch(fetchUserOrders(userID));
    }
    else {
      let userNaya = {
        message: "login successfully",
        token: getItem(StorageItem.JwtToken) || getSessionItem(StorageItem.JwtToken),
        user: getItem(StorageItem.LoggedInUser) || getSessionItem(StorageItem.LoggedInUser)
      }
      dispatch(userDobaraSetKr(userNaya));
      setFormData(userNaya)
    }
  }, [dispatch, user]);
  

  useEffect(() => {
    if (open === false) {
      dispatch(fetchFavouriteItem());
    }

  }, [dispatch, open])

  const handleClickOpen = (item) => {
    setOpen(true);
    setDeleteFavID(item)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addToBasket = (item) => {
    const cartData =
    {
      productId: item
    }

    dispatch(postCartItem(cartData));
    if (getItem(StorageItem.JwtToken)) {
      dispatch(showCartAlert());

    }
  }

  const deleteItem = () => {
    const id =
    {
      productId: deleteFavID
    }
    dispatch(deleteFavItem(id));
    setOpen(false);

  }

  const ProductDetail = (event, item) => {
    event.preventDefault();
    navigate(`/ProductDetail/${item?.slug}`)
    dispatch(fetchProductDetail(item?.slug))
  }

  const handleSubmit = (event) => {

    event.preventDefault();
    dispatch(updateUserInfo({id: userID, formdata: formData}))
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };
  const sendEmail = (event) => 
  {
    event.preventDefault();
    dispatch(postForgetPassword(passwordformData))
  }

  
  const PasswordhandleChange = (event) => {
    setPasswordFormData({
      ...passwordformData,
      [event.target.name]: event.target.value
    });

  };

  const orderDetailClick = (id) => 
  {
    setSelectedContent('orderDetail');
    dispatch(fetchUserOrderDetail(id));
  }

  const orderPageChange = (page) => {
    setCurrentPage(page);
  };
  const currentData = userOrder?.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

  const wishlistPageChange = (page) => {
    setWishListpage(page);
  };
  const wishListpageData = favItem?.slice((wishListpage - 1) * wishitemPerPage, wishListpage * wishitemPerPage);

  return (
    <div className="dashboard-container">
      <div className="menu-section">
        <div className="logo-section">
          <img src={logoImage} alt="" />
        </div>
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className={`nav-link ${selectedContent === 'dashboard' ? 'active' : ''}`} onClick={() => handleItemClick('dashboard')}>
              <AiOutlineUnorderedList/> My Orders
            </a>
          </li>
          <li className="nav-item">
       
            <a
              className={`nav-link ${selectedContent === 'profile' ? 'active' : ''}`}

              onClick={() => handleItemClick('profile')}
            >
               <MdOutlineFavoriteBorder /> Wishlist
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${selectedContent === 'messages' ? 'active' : ''}`}

              onClick={() => handleItemClick('messages')}
            >
            <BiUser /> Profile
            </a>
          </li>
          <li className="nav-item">
            <a className='nav-link' onClick={userLogout}><GoSignOut /> Sign Out</a>
          </li>

        </ul>
      </div>
      <div className="content-section">
        {selectedContent === 'dashboard' && (
          <div className='content-area'>
            <h1>Orders</h1>
            <table className="table mt-4">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Total Items</th>
                  <th>Sub Total</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentData?.map(order => (
                  <tr key={order._id}>
                    <td>{order?._id}</td>
                    <td>{order?.total_items}</td>
                    <td>${order?.subtotal.toFixed(2)}</td>
                    <td>{order?.createdAt}</td>
                    <td><span className={`status ${order.status === 'Pending'
                      ? 'NOT_PROCESSED'
                      : order.status === 'Cancel'
                        ? 'cancel'
                        : order.status === 'Shipped'
                          ? 'shipped'
                          : ''
                      }`}>{order.status}</span></td>
                    <td>
                      <button className='details-button' onClick={() => orderDetailClick(order?._id)}>Details</button>
                      {
                        order?.status === 'Pending' ?
                        <button className='cancel-button'>Cancel Order</button>
                        : ''
                      
                      }
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginate totalRecords={userOrder?.length} recordsPerPage={recordsPerPage} onPageChange={orderPageChange} />
          </div>
        )}
        {selectedContent === 'profile' && (
          <div className='content-area'>
            <h1>Wishlist</h1>
            <table className="table mt-4">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Stock</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  wishListpageData?.length === 0 ? (
                    <tr>
                      <td colSpan="5">
                        <div className="empty_list_section">
                          <h3 className='empty_list_text'>No products were added to the wishlist</h3>
                        </div>
                      </td>

                    </tr>

                  ) :
                    (wishListpageData?.map((fav, index) => {
                      return <tr key={index}>
                        <td className='table_products'>
                          <div className="table_product_section">
                            <div className="table_product_img_section">
                              <img src={fav?.main_image?.url} alt="" />
                            </div>
                            <div className="table_product_title_section">
                              <h2 className="card_title" onClick={(event) => ProductDetail(event, fav)}>{fav?.title} </h2>
                            </div>
                          </div>
                        </td>
                        <td className='table_price'>
                          <div className="table_product_price_section">
                            <h3>£ {fav?.price}</h3>
                          </div>
                        </td>
                        <td className='table_stock'>
                          <div className="table_product_stock_section">
                            <p className={fav?.quantity_in_stock ? 'product_available_stock' : 'product_out_of_stock'}>{fav?.quantity_in_stock > 0 ? "Available Stock" : "Out of Stock"}</p>
                          </div>
                        </td>
                        <td className='table_cart_btn'>
                          <div className="table_cart_section">

                            {fav?.quantity > 0 && <div className='counter_section'>
                              <span className='counter_quantity_heading'>Quantity:</span>
                              <button className='counter_minus' disabled={fav?.quantity === 1} ><HiMinusSm className='counter_icon' /></button>
                              <span className='counter'>{fav?.quantity}</span>
                              <button className='counter_plus'><HiPlusSm className='counter_icon' /></button>
                            </div>
                            }

                            <button className='product_cart_add_section' onClick={() => addToBasket(fav?._id)}>Add to basket <BsCartPlus /></button>

                          </div>
                        </td>
                        <td className='table_cross_icon'>
                          <div className="table_cross_icon_section">
                            <RxCross2 className='table_cross_icon' onClick={() => handleClickOpen(fav?._id)} />
                            <Dialog
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"Are you sure you want to remove this item in your whishlist?"}
                              </DialogTitle>
                              <DialogActions>
                                <Button onClick={handleClose}>No</Button>
                                <Button onClick={deleteItem} autoFocus>
                                  Yes
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </td>
                      </tr>
                    })
                    )
                }
              </tbody>
            </table>
            <Paginate totalRecords={favItem?.length} recordsPerPage={wishitemPerPage} onPageChange={wishlistPageChange} />
          </div>
        )}
        {selectedContent === 'messages' && (
          <div className="content-area">
            <h1 className='mb-4'>Profile</h1>
            <form onSubmit={handleSubmit}>
            <div className="user-profile-area">
              <div className="row">
                <div className="col-lg-6">
                  <TextField
                    required
                    label="First Name"
                    fullWidth
                    type="text"
                    name="first_name"
                    value={formData?.first_name || formData?.first_name || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">

                  <TextField
                    required
                    label="Last Name"
                    fullWidth
                    type="text"
                    name="last_name"
                    value={formData?.last_name || formData?.last_name || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <TextField
                    label="Company Name(optional)"
                    fullWidth
                    margin="normal"
                    type="text"
                    name="company_name"
                    value={formData?.company_name || formData?.company_name || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <Box sx={{ minWidth: 120 }} className="mt-3">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Country/Region</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        required
                        value={formData?.country || formData?.country || 'Angola'}
                        label="Country/Region"
                        name="country"
                        onChange={handleChange}
                      >
                        {
                          country?.countries?.map((country, index) => {
                            return <MenuItem value={country?.name} key={index}>{country?.name}</MenuItem>
                          })
                        }

                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="col-lg-6">

                  <TextField
                    required
                    label="Street Address"
                    fullWidth
                    margin="normal"
                    placeholder="House number and street name"
                    type="text"
                    name="street_address"
                    value={formData?.street_address || formData?.street_address || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <TextField
                    required
                    label="Town / City"
                    fullWidth
                    margin="normal"
                    type="text"
                    name="city"
                    value={formData?.city || formData?.city || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <TextField
                    required
                    label="Postcode"
                    fullWidth
                    margin="normal"
                    type="number"
                    name="postal_code"
                    value={formData?.postal_code || formData?.postal_code || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <TextField
                    required
                    label="Phone"
                    fullWidth
                    margin="normal"
                    type="number"
                    name="phone_number"
                    value={formData?.phone_number || formData?.phone_number || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <TextField
                    required
                    label="Email Address"
                    fullWidth
                    margin="normal"
                    type="email"
                    name="email_address"
                    value={formData?.email_address || formData?.email_address || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="update-btn-section mt-4">
              <button className='payment_btn' type='submit'>Update</button>
              </div>
              </div>

            </form> 

            <h1 className='mb-4'>Change Password</h1>
            <form>
                        <TextField
                        required
                        label="Email"
                        fullWidth
                        type="email"
                        placeholder="Please enter your email address"
                        margin="normal"
                        value={passwordformData?.email_address}
                        name='email_address'
                        onChange={PasswordhandleChange}
                      />
                      <div className="update-btn-section mt-4">
                      <button className='payment_btn' onClick={sendEmail}>Reset Password</button>
                      </div>
                      </form>
          </div>

          
        )}
        {selectedContent === 'orderDetail' && (
          <div className='content-area'>
            <h1>Order Detail</h1>
            <table className="table mt-4">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>price</th>
                  <th>Quantity</th>
                  <th>Item Subtotal</th>
     

                </tr> 

              </thead>
              <tbody>
                {orderDetails?.items?.map(order => (
                  <tr key={order._id}>
                   
                    <td>
                    <div className="order-detail-content-area">
                    <img src={order?.main_image?.url} className='productImg' alt="" />
                    </div>
                    </td>
                    <td>
                    <div className="order-detail-content-area">
                    {order?.title}
                      </div>
                    </td>
                    <td>
                    <div className="order-detail-content-area">
                    {order?.price}
                      </div>
                    </td>
                    <td>
                    <div className="order-detail-content-area">
                    {order?.quantity}
                      </div>
                   </td>
                   <td>
                    <div className="order-detail-content-area">
                    ${order?.item_subtotal.toFixed(2)}
                      </div>
                   </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>

  )
}

export default UserDashboard