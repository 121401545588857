import React from 'react'
import './EmailModal.css'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { hideEmailModel } from '../../Store/Slices/Cart_Item';
import img from '../../Images/gmailIcon.jpeg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function EmailModal() {
  const open = useSelector((state) => state.cartItem?.emailMsg);
  const dispatch = useDispatch();
  const handleClose = () => 
  {
    dispatch(hideEmailModel());
  }
  return (
    <div>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Typography id="modal-modal-image">
         <img src={img} alt="" />
        </Typography>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Email has been sent
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Please check your account
        </Typography>
      </Box>
    </Modal>
  </div>
  )
}

export default EmailModal