import React from 'react';
import './AboutUs.css';
import Breadcrum from '../../Components/BreadCrum/Breadcrum';
import img from '../../Images/aboutus_img-1.jpg'

function AboutUs() {
    return (
        <div className="about_us_wrapper">
            <div className="inner-sec-container">
                <div className="about_us_parent_section">
                    <Breadcrum item={""} />
                    <div className="about_us_section mt-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about_us_img_section">
                                    <img src={img} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about_us_short_desc_section">
                                    <h2>ABOUT US</h2>
                                    <p>CT Bikes (formerly Polaris Bikes) ltd. was formed in 2000 by a family collaboration of former bike engineers and assemblers. The company has worked hard to develop its business and reputation, with a successful expansion to our new premises at Osborne Mill, Oldham in 2011.</p>
                                    <p>CT Bikes has always aimed to provide a wide range of bikes, parts and accessories at the best prices, whilst maintaining great customer service. With a long history in the bike industry, we have a wealth of knowledge and expertise and can advise on all aspects of your requirements.</p>
                                    <p>Please note that for your security, CT Bikes do not retain your payment card information. If you pay by paypal or credit/debit card by phone, the payment processors may retain your information. To find out more visit www.paypal.co.uk or www.firstdatams.co.uk</p>
                                </div>
                            </div>
                        </div>
                        <div className="about_us_detai_desc_section mt-5">
                        <p>Being a leading bike retailer allows us to stock an outstanding range of men’s, women’s and kids bikes in the latest designs. Within our range, you’ll find Many Brands Like Tiger bikes,Viking bikes,Barracuda bikes,bikes from Cuda and many more,  bike similar  in conparision to many Large Retailer for example Halford,  Decathalon, Sports Direct to name a few. Our range covers all the riding disciplines and has something for each and every rider, with mountain bikes, full suspension mountain bikesand gravel bikesfor off-road lovers, road bikesfor speed fanatics, BMX bikesfor tricksters and hybrid bikesor folding bikesideal city for commuting. We also stock some of the UK’s best electric bikes on the market from the likes of  Insync Bike, Raleigh and Lombardo. And because electric mountain bikes are now more readily available, it’s easier to find an e-bike that suits you and your riding style. With an integrated electric motor, electric bikes provide a reliable solution and more efficient solution for leisurely rides and daily commutes. If you are commuting, folding electric bikes are a great option, especially if you travel frequently with a rucksack or heavy luggage. The compact nature of these bikes means they are well-suited to busy journeys that feature public transport.</p>
                        <p>At C T Bikes , we also take pride in our range of kids bikes.It features some of the coolest designs from top brands such as Disney, Bobbin, Raleigh and Cuda. Our range includes boy’s, girl’s and unisex bikes for all age groups ranging from 2-10+ years. So if you’re on the lookout for your child’s first bike,you’ll find colourful designs and familiar movie and TV characters that your kids will love. And because they’re designed with little hands and feet in mind, most of our bikes feature stabilisers which can be removed once your son or daughter learns how to cycle. Also, we stock a great selection of junior bikes which bring adult levels of comfort, safety and performance. Their lightweight alloy frame means they are excellent for transporting and loading in and out of vehicles.</p>
                        <p>Let C T Bikes take care of all your bike repair and bike servicing needs .</p>
                        <p>What’s more, all bikes available online can be built by our team of experts at a Warehouse  Alternatively, experience our trusted delivery service to get your bike delivered to your home or work. And to ensure you’re fully kitted out your cycling adventure, check out our range of cycling accessories .</p>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs